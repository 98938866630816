section.section-post-info {
    padding-bottom: 15px;

    h2 {
        font-weight: normal;
        font-size: 20px;
        margin-bottom: 45px;

        @include media('≥1024px') {
            font-size: 30px;
            margin-bottom: 45px;
        }
    }

    .card-resources-holder {
        .col-cards {
            @include media('≥1024px') {
                max-width: 33.3333333%;
                flex: 0 0 33.3333333%;
            }
        }
    }

    .card-resources {
        min-height: 180px;

        @include media('≥1024px') {
            min-height: 260px;
        }

        @media print {
            min-height: auto;
        }

        .content {
            // padding-top: 39px;
        }

        .card-title {
            line-height: 1.45;

            @include media('≥1024px') {
                font-size: 30px;
            }
        }
    }
}