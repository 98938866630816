@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?lb8mgv');
    src:  url('fonts/icomoon.eot?lb8mgv#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?lb8mgv') format('truetype'),
      url('fonts/icomoon.woff?lb8mgv') format('woff'),
      url('fonts/icomoon.svg?lb8mgv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-search:before {
    content: "\e900";
  }
  