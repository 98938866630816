.btn {
  @include button-color($btn-background, $btn-color, $btn-border, $btn-hover-background, $btn-hover-border, $btn-hover-color);

  // border: $btn-border;
  border-radius: $btn-border-radius;
  display: inline-block;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  letter-spacing: .03em;
  padding: $btn-padding-sm;
  margin: 0 0 $vertical-rhythm;
  vertical-align: top;
  text-align: center;
  transition: background .3s, color .3s;
  font-family: $base-font;
  outline: none;

  @include media('≥768px') {
    padding: $btn-padding;
  }

  &-default {
    @include button-color($btn-background, $btn-color, $btn-border, $btn-hover-background, $btn-hover-border, $btn-hover-color);
  }

  &-primary,
  input[type="submit"] {
    @include button-color($btn-background-primary, $btn-color-primary, $btn-border-primary, $btn-hover-background-primary, $btn-hover-border-primary, $btn-hover-color-primary);
  }

  &-warning {
    @include button-color($btn-background-warning, $btn-color-warning, $btn-border-warning, $btn-hover-background-warning, $btn-hover-border-warning, $btn-hover-color-warning);
  }

  &-sm {
    border-radius: $btn-border-radius-sm;
    padding: 4px 10px;
  }
}

.btn-group {
  display: flex;

  > .btn {
    + .btn {
      border-left: 0;
    }
  }
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;

  .btn {
    margin: 0 0 10px;
    max-width: 50%;

    +.btn {
      margin-left: 20px;
    }
  }
}
