.section-sponsors {
    padding-top: 58px;
    padding-bottom: 160px;
    
    @include media('≥576px') {
        padding-top: 48px;
        padding-bottom: 60px;
    }
    
    @include media('≥768px') {
        padding-top: 25px;
        padding-bottom: 60px;
    }

    @include media('≥1440px') {
        padding-top: 78px;
    }

    .container {
        position: relative;
        z-index: 1;
    }
    
    hr {
        margin-top: 0;
        margin-bottom: 47px;
    }

    .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        
        @include media('≥576px') {
            margin-bottom: 12px;
        }

        .heading,
        .btn {
            margin-bottom: 0;
        }

        .btn {
            position: absolute;
            left: 40px;
            min-width: 240px;
            bottom: -104px;
            min-width: 240px;
            padding-top: 11px;
            padding-bottom: 11px;

            @include media('≥576px') {
                position: static
            }
        }
    }

    .partners-list {
        @extend %listreset;

        @include media('≥576px') {
            display: flex;
            align-items: center;
        }

        li {
            + li {
                margin-top: 31px;

                @include media('≥576px') {
                    margin-left: 50px;
                    margin-top: 0;
                }
            }
        }
    }
}
