#footer {
	background: $base-background-footer;
}

.footer-nav {
	padding-top: 60px;
	padding-bottom: 54px;
	background-color: #B4C2CE;

	@include media('≥1440px') {
		padding-bottom: 30px;
	}

	.df-row {
		margin: 0 -20px;
	}

	.menu-item {
		max-width: 100%;
		flex: 0 0 100%;
		color: #2C2D44;
		font-size: 16px;
		padding: 0 20px 0 20px;

		@include media('≥576px') {
			max-width: 50%;
			flex: 0 0 50%;
			padding: 0 20px 36px 20px;
		}

		@include media('≥768px') {
			padding: 0 20px 36px 20px;
			max-width: 33.3333333%;
			flex: 0 0 33.3333333%;
		}

		@include media('≥1024px') {
			max-width: 25%;
			flex: 0 0 25%;
			padding: 0 20px 20px 20px;
		}

		@include media('≥1440px') {
			max-width: 16.6666667%;
			flex: 0 0 16.6666667%;
		}

		.title {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 9px;
			font-size: 18px;
			line-height: 1.4;
			font-family: $font-family-roboto-slab;
			font-weight: 500;
			color: #2C2D44;

			@include media('≥576px') {
				margin-bottom: 16px;
			}
		}

		a {
			color: inherit;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.footer-menu {
	@extend %listreset;

	line-height: 1.5;

	li {
		+ li {
			margin-top: 10px;
		}

		&.active {
			a {
				text-decoration: none;
			}
		}


	}
}

.footer-contacts {
	font-size: 18px;

	.inner {
		@include media('≥1024px') {
			display: flex;
		}

		.col {
			display: flex;


			&:first-child {
				flex: 1;
				min-width: 1px;
			}

			&:nth-child(2) {
				max-width: 460px;
				flex: 0 0 460px;
			}

			> * {
				width: 100%;
			}
		}
	}

	h4 {
		font-weight: 400;
		margin-bottom: 15px;
		font-family: $font-family-roboto-slab;
	}

	a {
		font-size: 18px;
		font-weight: 700;
		text-decoration: underline;
		color: #544861;

		&:hover {
			text-decoration: none;
		}
	}

	.subscribe-block {
		max-width: 480px;
		padding: 60px 0 68px 0;

		@include media('≥1024px') {
			padding: 60px 42px 68px 0;
			max-width: 100%;
		}

		@include media('≥1440px') {
			padding: 60px 60px 68px 0;
		}

		h4 {
			font-weight: 700;
			margin-bottom: 20px;
			color: $black;

			@include media('≥768px') {
				font-weight: 400;
				margin-bottom: 15px;
			}
		}

		p {
			margin-bottom: 28px;
			line-height: 1.4;
		}

		form {
			margin-bottom: 26px;
			max-width: 440px;

			@include media('≥576px') {
				display: flex;
			}

			@include media('≥768px') {
				
			}

			label {
				display: inline-block;
				vertical-align: middle;
				max-width: 240px;
				width: 100%;
				margin-bottom: 28px;

				@include media('≥576px') {
					flex: 1;
					max-width: none;
					min-width: 1px;
					margin-bottom: 0;
				}

				+ .btn {
					@include media('≥576px') {
						margin-left: 14px;
					}
				}
			}

			input {
				width: 100%;
			}

			.btn {
				max-width: 124px;
				flex: 0 0 124px;
				width: 100%;
				margin-bottom: 0;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.contact-block {
		position: relative;
		padding: 60px 0 68px 0;
		background-color: #DCB9AE;
		color: #4F2121;
		z-index: 1;

		@include media('≥1024px') {
			padding: 60px 0 68px 60px;
		}

		&:after {
			content: " ";
			position: absolute;
			left: 100%;
			top: 0;
			bottom: 0;
			width: 100vh;
			z-index: -1;
			background-color: #DCB9AE;
		}
		
		&:before {
			@include media('<1024px') {
				content: " ";
				position: absolute;
				right: 100%;
				top: 0;
				bottom: 0;
				width: 100vh;
				z-index: -1;
				background-color: #DCB9AE;
			}
		}

		address {
			font-size: 18px;
			margin-bottom: 15px;
			font-style: normal;

			p {
				margin-bottom: 0;

				+ p {
					margin-top: 7px;

					// @include media('≥768px') {
					// 	margin-top: 13px;
					// }
					
					@include media('≥1024px') {
						margin-top: 7px;
					}
				}
			}
		}

		a {
			color: inherit;
			font-size: 18px;
		}
	}
}

.footer-bottom {
	padding: 38px 0 39px;
	background-color: $black;
	color: $white;

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.inner {
		@include media('≥768px') {
			display: flex;
			justify-content: space-between;
		}
	}

}

.footer-bottom-menu {
	@extend %listreset;

	font-size: 16px;
	margin-bottom: 40px;

	@include media('≥768px') {
		display: flex;
		// font-weight: 700;
		margin-bottom: 0;
	}

	li {
		+ li {
			margin-top: 15px;

			@include media('≥768px') {
				margin-left: 40px;
				margin-top: 0;
			}
		}

		&.active {
			a {
				text-decoration: none;
			}
		}
	}
}

.copyright {
	font-size: 16px;
	line-height: 1.4;

	@include media('<768px') {
		max-width: 200px;
		text-align: right;
		margin-left: auto;
	}

	p {
		margin-bottom: 0;
	}
}