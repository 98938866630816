/*
Theme Name: Base
Author: Anonymous
Author URI:
Version: 1
Description: Base theme for Wordpress
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: base
Tags: one-column, two-columns
Theme URI:
*/

// devices viewport width starts at:
$breakpoints: (
  xs-phone: 320px,
  phone: 480px,
  tablet: 768px,
  desktop: 1024px,
  widescreen: 1300px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/cms-reset';
@import 'base/forms';
@import 'base/wp-reset';
@import 'base/typography';

@import 'layout/header';

@import 'vendors/fonts';
@import 'vendors/slick';

@import 'components/heading';
@import 'components/cards';
@import 'components/buttons';
@import 'components/breadcrumbs';

@import 'components/sections/section-banner';
@import 'components/sections/section-cards';
@import 'components/sections/section-upcoming-events';
@import 'components/sections/section-popular-resources';
@import 'components/sections/section-sponsors';
@import 'components/sections/section-post-info';
@import 'components/sections/section-search-result';
@import 'components/sections/section-content';

@import 'layout/common';
@import 'layout/footer';