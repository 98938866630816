.card {
    @include animate(background-color);

    display: flex;
    flex-direction: column;
    background-color: $olive;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: $white;
    max-width: 220px;
    width: 100%;

    @include media('<480px') {
        margin-left: auto;
        margin-right: auto;
    }

    > * {
        width: 100%;
    }

    header {
        height: 120px;
        max-height: 120px;
        overflow: hidden;
        display: flex;

        .card-img {
            @include animate(transform);

            display: flex;
            width: 100%;
            background-position: center;
        }
    }

    .content {
        padding: 30px 36px 29px 40px;
    }

    .card-title {
        font-size: 20px;
        text-decoration: underline;
        min-height: 50px;
        margin-bottom: 20px;
        color: $white;
        font-family: $font-family-roboto-slab;
        line-height: 1.3;
    }

    time {
        font-size: 16px;
        color: $white;
    }

    // .card hover
    &:hover {
        background-color: lighten($olive,10%);

        .card-img {
            transform: scale(1.1);
        }
    }
}

.card-text {
    @include animate(opacity);

    position: relative;
    font-size: 18px;
    line-height: 1.4;
    z-index: 1;

    &:after {
        content: " ";
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 50vh;
        z-index: -1;
        background-color: currentColor;
    }

    &:hover {
        opacity: .8;
    }

    .inner {
        color: $white;
        padding: 25px 42px;

        @include media('≥768px') {
            padding: 35px 42px 44px 39px;
        }

        @include media('≥1440px') {
            padding: 44px 30px 50px 40px;
        }
    }

    .title {
        display: inline-block;
        vertical-align: middle;
        color: $white;
        margin-bottom: 3px;
        text-decoration: underline;
        font-family: $font-family-roboto-slab;
    }

    p {
        margin-bottom: 0;
    }
}

.card-resources {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: $white;
    text-align: center;
    transition: .3s background-color;
    cursor: pointer;
    min-height: 180px;

    @include media('≥768px') {
        min-height: 120px;
    }

    &:hover {
        background-color: $black !important;
    }

    .content {
        padding: 19px 40px 19px 40px;
        
        @include media('≥768px') {
            padding: 9px 40px 18px 40px;
        }
    }

    .card-title {
        text-decoration: underline;
        color: $white;
        font-family: $font-family-roboto-slab;
        margin-bottom: 0;
    }

    a {
        color: inherit;
    }
}

.card-news {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 33px;

    time {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 6px;
        color: #617798;
    }

    .card-title {
        margin-bottom: 12px;
        color: #544861;
        text-decoration: underline;
        transition: .3s color;
        font-size: 18px;

        @include media('≥1024px') {
            margin-bottom: 1px;
        }
        
        @include media('≥1440px') {
            margin-bottom: 12px;
        }

        &:hover {
            color: $purple;
        }

        a {
            color: inherit;
        }
    }

    p {
        margin-bottom: 0;
    }
}