// Typography

@include title-size('≥');

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $headings-font-family;
  color: $headings-color;
  font-weight: bold;
  margin: 0 0 10px;

  @include media('≥tablet') {
    margin-bottom: 20px;
  }
}

h1,
.h1 {
  font-family: $font-family-roboto-slab;
  line-height: 1.3;
}

h2,
.h2 {
  margin-bottom: 13px;
  font-family: $font-family-roboto-slab;
  line-height: 1.7;
  font-weight: normal;
  
  @include media('≥1024px') {
    line-height: 1.4;
    margin-bottom: 35px;
  }
}

.h3,
h3 {
  color: #77775F;
  line-height: 1.4;
  margin-bottom: 32px;
}

.h4,
h4 {
  // font-family: $font-family-roboto-slab;
  color: #4F2121;
  margin-bottom: 28px;
}

p {
  margin: 0 0 32px;

  a {
    font-weight: 700;
    text-decoration: underline;
  }
}

a {
  color: $base-link-color;
  text-decoration: $text-decoration;
  outline: none;

  &:hover,
  &:focus {
    color: $base-link-hover-color;
  }
}

a[href*="mailto:"] {
  word-wrap: break-word;
}
