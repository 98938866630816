.section-popular-resources {
    // padding-bottom: 29px;

    @include media('≥1440px') {
        // padding-bottom: 29px;
    }
    
    .df-row {
        margin: 0 -30px;
    }

    .col {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0 30px;

        + .col {
            margin-top: 36px;
            
            @include media('≥1024px') {
                margin-top: 0;
            }
        }


        &:first-child {
            @include media('≥1024px') {
                max-width: 52%;
                flex: 0 0 52%;
            }

            @include media('≥1440px') {
                max-width: 53.5%;
                flex: 0 0 53.5%;
            }
        }

        &:nth-child(2) {
            @include media('≥1024px') {
                max-width: 48%;
                flex: 0 0 48%;
            }

            @include media('≥1440px') {
                max-width: 46.5%;
                flex: 0 0 46.5%;			
            }
        }
    }
}

.card-resources-holder {

    .df-row {
        margin: 0 -10px;
    }
    
    .col-cards {
        display: flex;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0 10px 20px 10px;

        @include media('≥576px') {
            max-width: 50%;
            flex: 0 0 50%;
        }

        @include media('≥768px') {
            max-width: 33.3333333%;
            flex: 0 0 33.3333333%;
        }

        @include media('≥1024px') {
            max-width: 50%;
            flex: 0 0 50%;
        }

        @include media('≥1440px') {
            max-width: 33.3333333%;
            flex: 0 0 33.3333333%;
        }

        > * {
            width: 100%;
        }
    }
}