.breadcrumbs-nav {
    padding: 60px 0 67px 0;

    @include media('≥1024px') {
        padding: 50px 0 45px 0;
    }
}

.breadcrumbs {
    @extend %listreset;

    display: flex;
    flex-wrap: wrap;
    color: #666;
    font-size: 16px;

    li {
        margin-right: 10px;
        margin-top: 10px;

        + li {

            &:before {
                content: ">";
                margin-right: 10px;
            }
        }
    }

    a {
        text-decoration: underline;
        color: inherit;
        transition: .3s color;

        &:hover {
            color: $purple;
        }
    }
}