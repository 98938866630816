[id="header"] {
  position: relative;
  background: $white;
  padding: 32px 0 29px 0;
  z-index: 2;

  .btn {
    @include media('≥1024px') {
      padding: 10px 22px;
    }
  }
}

.header-holder {
  display: flex;
  align-items: center;
}

.logo {
    width: 162px;
    min-width: 100px;

    @include media('≥1024px') {
      margin-left: -5px;
    }
}

.top-menu {
    @extend %listreset;

    order: 1;
    font-size: 20px;

    @include media('≥1024px') {
      margin-bottom: 12px;
      font-size: 18px;
    }
    
    li {
      &:last-child {

        span {
          @include media('≥1024px') {
            position: absolute!important;
            width: 1px!important;
            height: 1px!important;
            padding: 0!important;
            margin: -1px!important;
            overflow: hidden!important;
            clip: rect(255,255,255,255)!important;
            white-space: nowrap!important;
            border: 0!important;
            color: $white;
          }
        }

        i {
          display: none;

          @include media('≥1024px') {
            display: block;
          }
        }
      }

      + li {
        @include media('≥1024px') {
          margin-left: 25px;
        }
      }
    }
}

.primary-menu-holder {
  @include media('≥1024px') {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .btn {
      margin-bottom: 0;

      @include media('<1024px') {
        background-color: transparent;
        border-radius: 0;
        padding: 13px 0;
        display: block;

        &:focus {
          background-color: transparent;
        }

        &:hover {
          background-color: $purple;
        }
      }

      @include media('≥1024px') {
        margin-left: 24px;
      }
  }
}

.primary-menu {
  @extend %listreset;

  @include media('≥1024px') {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  li {
    &:first-child {
      @include media('≥1024px') {
        display: none;
      }
    }

    + li {
      @include media('≥1024px') {
        margin-left: 31px;
      }
      
      @include media('≥1440px') {
        margin-left: 31px;
      }
    }
  }
}

[id="nav"] {
  @extend %clearfix;

  flex-grow: 1;
}

.nav-drop {
  @include animate(all);

  display: flex;
  flex-direction: column;
  background: $black;
  position: absolute;
  color: $white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 50px 0 96px 0;
  font-size: 20px;
  opacity: 0;
  visibility: hidden;
  text-align: center;

  @include media('≥1024px') {
    display: block;
    padding: 0;
    position: static;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    color: $black;
    text-align: left;
  }

  .nav-active & {
    opacity: 1;
    visibility: visible;
  }

  > ul {

    @include media('≥1024px') {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  li {
    position: relative;
    list-style: none;
    transition: .3s background-color;

    &.active {
      @include media('<1024px') {
        background-color: $purple;
      }

      a {
        @include media('≥1024px') {
          color: $purple;
        }
      }
    }

    &:hover {
      @include media('<1024px') {
        background-color: $purple;
      }

      > a {
        @include media('≥1024px') {
          color: $purple;
        }
      }

      > ul {
        display: block;
      }
    }

    > ul {
      background: $gray;
      display: none;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: 2;

      @include media('≥1024px') {
        padding-top: 10px;
        width: 120px;
        position: absolute;
      }

      ul {
        @include media('≥1024px') {
          padding-top: 0;
          position: absolute;
          left: 100%;
          top: 0;
          width: 120px;
        }
      }

      li {
        padding: 0 10px;
      }
    }
  }

  a {
    text-decoration: underline;
    display: block;
    padding: 13px 0;

    @include media('≥1024px') {
      padding: 0;
    }

    @include media('≥1024px') {
        text-decoration: none;
    }

    &:not(.btn) {
      color: inherit;
      transition: .3s color;
    }
  }
}

.nav-opener {
  position: relative;
  width: 58px;
  height: 35px;
  font-size: 0;
  line-height: 0;
  float: right;
  margin-right: -7px;
  outline: none;

  @include media('≥1024px') {
    display: none;
  }

  &:before,
  &:after,
  span {
    @include animate(transform);

    background: #617798;
    position: absolute;
    top: 13px;
    left: 15%;
    right: 15%;
    height: 9px;
    border-radius: 8px;
  }

  &:before,
  &:after {
    content: '';
    top: 0;
  }

  &:after {
    top: auto;
    bottom: 0;
  }

  &:hover {
    opacity: 0.9;
  }

  .nav-active & {

    span {
      opacity: 0;
      background: $black;
    }

    &:after,
    &:before {
      transform: rotate(45deg);
      top: 16px;
      left: 15%;
      right: 15%;
      background: $black;
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.resize-active .nav-drop,
.resize-active .nav-drop > ul {
  transition: none !important;
}