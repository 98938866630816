body {
  width: 100%;
}

[id="wrapper"] {
  width: 100%;
  overflow: hidden;
  background: $base-background-color;
}

.container {
  @extend %clearfix;

  max-width: $max-width-center-container;
  margin: 0 auto;
  padding: 0 38px;
    
  @include media('≥768px') {
    padding: 0 36px;
  }
  
  @include media('≥1024px') {
    padding: 0 42px;
  }

  @include media('≥1440px') {
    padding: 0 15px;
  }

}

a {
  transition: .3s color;
}

hr,
.hr {
  display: block;
  height: 5px;
  background-color: #B4C2CE;
  border: 0;
  margin: 41px 0 53px 0;
}

table {
  width: 100%;
  border: 2px solid #ddd;
  margin-top: 45px;
  margin-bottom: 30px;

  th {
    background-color: #EDEDF3;
    font-weight: normal;
    text-align: left;
    border: 2px solid #ddd;
  }

  th,
  td {
    width: 25%;
    padding: 22px 29px;
  }

  p {
    margin-bottom: 0;
  }
}

figure {
  margin-left: 0;
  margin-right: 0;

  img {
    border: 2px solid #707070;
    border-radius: 8px;
    vertical-align: top;
    line-height: 1;
  }

  figcaption {
    margin-top: 10px;
  }
}

.accessibility {
  display: block;
  padding: 15px;
  background-color: #816E96;
  color: $white;

  &:not(:focus) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(255,255,255,255)!important;
    white-space: nowrap!important;
    border: 0!important;
    color: $white;
  }
}

.hidden {
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(255,255,255,255)!important;
  white-space: nowrap!important;
  border: 0!important;
  color: $white;
}

noscript {
  div {
    text-align: center;
    padding: 15px 5px;
    color: crimson;
    background-color: #afaeae;
  }
}