.section-search-result {
    padding-bottom: 38px;

    h3 {
        margin-bottom: 30px;

        @include media('≥1024px') {
            margin-bottom: 52px;
        }
    }
}