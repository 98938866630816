.section-content {
    padding-bottom: 7px;

    ul {
        @extend %listreset;

        margin-bottom: 40px;

        li {
            padding-left: 12px;

            + li {
                margin-top: 3px;
            }

            &:before {
                display: inline-block;
                vertical-align: middle;
                margin-right: 20px;
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $black;
            }
        }
    }

    .btn {
        padding: $btn-padding;
    }

    .table-holder {
        overflow-x: auto;
    }
}