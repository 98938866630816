@charset "UTF-8";
/*
Theme Name: Base
Author: Anonymous
Author URI:
Version: 1
Description: Base theme for Wordpress
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: base
Tags: one-column, two-columns
Theme URI:
*/
.tagcloud, .top-menu, .primary-menu, .breadcrumbs, .section-upcoming-events .card-block .slick-dots, .section-sponsors .partners-list, .section-content ul, .footer-menu, .footer-bottom-menu {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after, dl:after, .widget:after, .search-form:after,
.post-password-form:after, .search-form p:after,
.post-password-form p:after, [id="nav"]:after, .container:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

.df-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -42px; }
  @media (min-width: 480px) {
    .df-row.df-rr-ph {
      flex-direction: row-reverse; } }
  @media (min-width: 480px) {
    .df-row.df-aic-ph {
      align-items: center; } }
  @media (min-width: 768px) {
    .df-row.df-rr-sm {
      flex-direction: row-reverse; } }
  @media (min-width: 768px) {
    .df-row.df-aic-sm {
      align-items: center; } }
  @media (min-width: 1024px) {
    .df-row.df-rr-md {
      flex-direction: row-reverse; } }
  @media (min-width: 1024px) {
    .df-row.df-aic-md {
      align-items: center; } }
  @media (min-width: 480px) {
    .df-row .col-ph-1 {
      flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 480px) {
    .df-row .col-ph-2 {
      flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 480px) {
    .df-row .col-ph-3 {
      flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 480px) {
    .df-row .col-ph-4 {
      flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 480px) {
    .df-row .col-ph-5 {
      flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 480px) {
    .df-row .col-ph-6 {
      flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 480px) {
    .df-row .col-ph-7 {
      flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 480px) {
    .df-row .col-ph-8 {
      flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 480px) {
    .df-row .col-ph-9 {
      flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 480px) {
    .df-row .col-ph-10 {
      flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 480px) {
    .df-row .col-ph-11 {
      flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 480px) {
    .df-row .col-ph-12 {
      flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row .col-sm-1 {
      flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 768px) {
    .df-row .col-sm-2 {
      flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 768px) {
    .df-row .col-sm-3 {
      flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 768px) {
    .df-row .col-sm-4 {
      flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 768px) {
    .df-row .col-sm-5 {
      flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 768px) {
    .df-row .col-sm-6 {
      flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 768px) {
    .df-row .col-sm-7 {
      flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 768px) {
    .df-row .col-sm-8 {
      flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 768px) {
    .df-row .col-sm-9 {
      flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 768px) {
    .df-row .col-sm-10 {
      flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 768px) {
    .df-row .col-sm-11 {
      flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row .col-sm-12 {
      flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 1024px) {
    .df-row .col-md-1 {
      flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 1024px) {
    .df-row .col-md-2 {
      flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 1024px) {
    .df-row .col-md-3 {
      flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 1024px) {
    .df-row .col-md-4 {
      flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 1024px) {
    .df-row .col-md-5 {
      flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 1024px) {
    .df-row .col-md-6 {
      flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 1024px) {
    .df-row .col-md-7 {
      flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 1024px) {
    .df-row .col-md-8 {
      flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 1024px) {
    .df-row .col-md-9 {
      flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 1024px) {
    .df-row .col-md-10 {
      flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 1024px) {
    .df-row .col-md-11 {
      flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 1024px) {
    .df-row .col-md-12 {
      flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 1440px) {
    .df-row {
      margin: 0 -15px; } }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

* {
  max-height: 1000000px; }

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  color: #000;
  background: #fff;
  font: 16px/1.4 "Open Sans", sans-serif, "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0; }
  @media (min-width: 768px) {
    body {
      font-size: 18px;
      line-height: 1.44; } }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top; }

.gm-style img {
  max-width: none; }

@media (max-width: 1023px) {
  iframe {
    width: 100%; } }

.resize-active * {
  transition: none !important; }

ul,
ol,
dl,
p,
h1,
h2,
h3,
h4,
h5,
h6,
address,
form,
table,
blockquote,
applet,
embed,
object,
iframe,
frameset {
  margin: 0 0 20px; }

blockquote {
  border-left: 4px solid #ddd;
  padding-left: 10px;
  margin-left: 0; }

table {
  border-collapse: collapse; }

th,
td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: left;
  vertical-align: top; }

th {
  text-align: center;
  vertical-align: middle; }

ul,
ol {
  padding-left: 20px; }

ul ul,
ul ol,
ol ul,
ol ol {
  padding-left: 20px;
  margin: 0; }

ul {
  list-style-type: disc; }

dl dt {
  float: left;
  clear: left;
  padding-right: 0.3em;
  font-weight: bold; }

dl dd {
  overflow: hidden; }

pre {
  max-width: 100%;
  overflow: auto; }

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none; }

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 22px;
  font-family: "Open Sans", sans-serif, "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 8px; }
  input[type='text']:not(textarea),
  input[type='tel']:not(textarea),
  input[type='email']:not(textarea),
  input[type='search']:not(textarea),
  input[type='password']:not(textarea),
  input[type='url']:not(textarea),
  textarea:not(textarea) {
    height: 44px; }
  input[type='text']:focus,
  input[type='tel']:focus,
  input[type='email']:focus,
  input[type='search']:focus,
  input[type='password']:focus,
  input[type='url']:focus,
  textarea:focus {
    outline: none;
    border-color: #000; }
  input[type='text']::-webkit-input-placeholder,
  input[type='tel']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='search']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  input[type='url']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #000; }
  input[type='text']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='search']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  input[type='url']::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    color: #000; }
  input[type='text']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='search']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  input[type='url']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #000; }
  input[type='text']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='search']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  input[type='url']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #000; }
  input[type='text'].placeholder,
  input[type='tel'].placeholder,
  input[type='email'].placeholder,
  input[type='search'].placeholder,
  input[type='password'].placeholder,
  input[type='url'].placeholder,
  textarea.placeholder {
    color: #000; }

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none; }

textarea {
  resize: vertical;
  vertical-align: top;
  overflow: auto; }

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  transition: background 0.5s ease-in-out;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  height: 44px;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  outline: none;
  cursor: pointer;
  border: #000;
  border-radius: 8px;
  background: #000; }
  button:hover,
  input[type='button']:hover,
  input[type='reset']:hover,
  input[type='file']:hover,
  input[type='submit']:hover {
    background: black;
    color: #fff; }

.search-form {
  max-width: 668px;
  margin-bottom: 31px; }
  @media (min-width: 1024px) {
    .search-form {
      margin-bottom: 31px; } }
  @media (min-width: 380px) {
    .search-form {
      display: flex; } }
  .search-form label {
    display: inline-block !important;
    vertical-align: middle;
    max-width: 240px;
    width: 100%;
    margin-bottom: 28px; }
    @media (min-width: 380px) {
      .search-form label {
        flex: 1;
        max-width: none;
        min-width: 1px;
        margin-bottom: 0; } }
    @media (min-width: 380px) {
      .search-form label + .btn {
        margin-left: 14px; } }
  .search-form input {
    width: 100%; }
  .search-form .btn {
    display: block;
    max-width: 100px;
    flex: 0 0 100px;
    width: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px; }

.nav-links,
.navigation,
.navigation-comments, .navigation-single {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px; }

.nav-links .next,
.nav-links .prev,
.navigation .next,
.navigation .prev,
.navigation-comments .next,
.navigation-comments .prev, .navigation-single .next a,
.navigation-single .prev a {
  background: #000;
  color: #fff;
  border: #000;
  padding: 10px 25px;
  font-size: 20px;
  line-height: 1.15; }
  .nav-links .next:hover,
  .nav-links .prev:hover,
  .navigation .next:hover,
  .navigation .prev:hover,
  .navigation-comments .next:hover,
  .navigation-comments .prev:hover, .navigation-single .next a:hover,
  .navigation-single .prev a:hover {
    background: black;
    color: #fff; }

@media (min-width: 768px) {
  [id="twocolumns"] {
    display: flex; } }

[id="content"] {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    [id="content"] {
      width: 70%; } }
  [id="content"]:only-child {
    width: 100%; }

[id="sidebar"] {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    [id="sidebar"] {
      width: 30%;
      padding-left: 30px; } }

.widget {
  margin-bottom: 20px; }
  .widget select {
    min-width: 150px;
    width: 100%; }

.search-form label,
.post-password-form label {
  display: block; }

.search-form input,
.post-password-form input {
  float: left;
  margin-right: 5px; }
  @media (min-width: 768px) {
    [id="sidebar"] .search-form input, [id="sidebar"]
    .post-password-form input {
      width: 100%;
      margin: 0 0 5px; } }
  .search-form input[type='submit'],
  .post-password-form input[type='submit'] {
    margin: 0; }

.nav-links,
.navigation,
.navigation-comments {
  position: relative; }
  .nav-links .screen-reader-text,
  .navigation .screen-reader-text,
  .navigation-comments .screen-reader-text {
    position: absolute;
    left: -99999px; }
  .nav-links .page-numbers,
  .navigation .page-numbers,
  .navigation-comments .page-numbers {
    margin: 0 3px; }
    .nav-links .page-numbers:first-child,
    .navigation .page-numbers:first-child,
    .navigation-comments .page-numbers:first-child {
      margin-left: 0; }
    .nav-links .page-numbers:last-child,
    .navigation .page-numbers:last-child,
    .navigation-comments .page-numbers:last-child {
      margin-right: 0; }
  .nav-links .next,
  .nav-links .prev,
  .navigation .next,
  .navigation .prev,
  .navigation-comments .next,
  .navigation-comments .prev {
    transition: background 0.5s ease-in-out;
    max-width: 49%; }
    @media (max-width: 767px) {
      .nav-links .next,
      .nav-links .prev,
      .navigation .next,
      .navigation .prev,
      .navigation-comments .next,
      .navigation-comments .prev {
        font-size: 0; }
        .nav-links .next:after,
        .nav-links .prev:after,
        .navigation .next:after,
        .navigation .prev:after,
        .navigation-comments .next:after,
        .navigation-comments .prev:after {
          content: '»';
          font-size: 20px; } }
  @media (max-width: 767px) {
    .nav-links .prev:after,
    .navigation .prev:after,
    .navigation-comments .prev:after {
      content: '«'; } }

.navigation-single {
  width: 100%; }
  .navigation-single .next,
  .navigation-single .prev {
    max-width: 49%;
    display: flex;
    align-items: center; }
    .navigation-single .next a,
    .navigation-single .prev a {
      transition: background 0.5s ease-in-out;
      display: block; }
      @media (max-width: 767px) {
        .navigation-single .next a,
        .navigation-single .prev a {
          font-size: 0; }
          .navigation-single .next a:after,
          .navigation-single .prev a:after {
            content: '»';
            font-size: 20px; } }
  @media (max-width: 767px) {
    .navigation-single .prev a:after {
      content: '«'; } }

.navigation-single {
  justify-content: space-between;
  align-items: stretch; }
  .navigation-single .next,
  .navigation-single .prev {
    padding: 0;
    margin: 0;
    max-width: 49%;
    display: flex;
    align-items: center;
    border: 0; }
    .navigation-single .next a,
    .navigation-single .prev a {
      padding: 10px 25px;
      border: #000; }

.comment-form label {
  display: block;
  padding-bottom: 4px; }

.comment-form input,
.comment-form textarea {
  margin: 0 4px 0 0;
  width: 100%; }
  @media (min-width: 768px) {
    .comment-form input,
    .comment-form textarea {
      max-width: 300px; } }
  .comment-form input[type='submit'],
  .comment-form textarea[type='submit'] {
    display: block;
    width: auto; }

.comment-form textarea {
  overflow: auto;
  margin: 0;
  height: 120px;
  min-height: 120px; }

.commentlist {
  margin: 0 0 1.2em; }
  .commentlist .edit-link {
    margin: 0; }
  .commentlist .avatar-holder {
    float: left;
    margin: 0 1.2em 4px 0; }

.commentlist-item .commentlist-item {
  padding: 0; }
  @media (min-width: 768px) {
    .commentlist-item .commentlist-item {
      padding: 0 0 0 2em; } }

.comment,
.commentlist-holder {
  overflow: hidden; }

.commentlist-item .commentlist-item,
.commentlist-item + .commentlist-item {
  padding-top: 20px; }

.widget_calendar th,
.widget_calendar td {
  text-align: center; }

.widget_calendar #prev {
  text-align: left; }

.widget_calendar #next {
  text-align: right; }

#wp-calendar {
  width: 100%; }

.wp-caption {
  max-width: 100%;
  clear: both;
  border: #000;
  padding: 4px;
  margin-bottom: 20px;
  text-align: center; }
  .wp-caption img {
    margin-bottom: 4px; }
  .wp-caption p {
    margin: 0; }

.aligncenter {
  margin: 0 auto 20px; }

img.aligncenter {
  display: block;
  margin: 0 auto; }

.alignleft {
  float: left;
  margin: 0 1.2em 4px 0; }

.alignright {
  float: right;
  margin: 0 0 4px 1.2em; }

.mejs-container {
  margin-bottom: 20px; }

.wp-caption-text,
.gallery,
.alignnone,
.gallery-caption,
.sticky,
.bypostauthor {
  height: auto; }

.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .gallery .gallery-item {
    margin: 0;
    padding: 0 10px 10px; }
    .gallery .gallery-item dt {
      float: none;
      width: 100%;
      margin: 0 0 10px;
      padding: 0; }

.gallery-columns-2 .gallery-item {
  max-width: 50%;
  width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
  width: 33.33%; }

.tagcloud {
  display: flex;
  flex-wrap: wrap; }

.comment-list .comment-body {
  margin-bottom: 20px; }

.comment-author img {
  margin-right: 10px; }

h1,
.h1 {
  font-size: 30px; }

h2,
.h2 {
  font-size: 30px; }

h3,
.h3 {
  font-size: 24px; }

h4,
.h4 {
  font-size: 20px; }

h5,
.h5 {
  font-size: 14px; }

h6,
.h6 {
  font-size: 14px; }

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 40px; }
  h2,
  .h2 {
    font-size: 30px; }
  h3,
  .h3 {
    font-size: 24px; }
  h4,
  .h4 {
    font-size: 20px; }
  h5,
  .h5 {
    font-size: 18px; }
  h6,
  .h6 {
    font-size: 16px; } }

@media (min-width: 1024px) {
  h1,
  .h1 {
    font-size: 40px; }
  h2,
  .h2 {
    font-size: 30px; }
  h3,
  .h3 {
    font-size: 24px; }
  h4,
  .h4 {
    font-size: 20px; }
  h5,
  .h5 {
    font-size: 18px; }
  h6,
  .h6 {
    font-size: 16px; } }

@media (min-width: 1300px) {
  h1,
  .h1 {
    font-size: 40px; }
  h2,
  .h2 {
    font-size: 30px; }
  h3,
  .h3 {
    font-size: 24px; }
  h4,
  .h4 {
    font-size: 20px; }
  h5,
  .h5 {
    font-size: 18px; }
  h6,
  .h6 {
    font-size: 16px; } }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: "Open Sans", sans-serif, "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  color: inherit;
  font-weight: bold;
  margin: 0 0 10px; }
  @media (min-width: 768px) {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    .h {
      margin-bottom: 20px; } }

h1,
.h1 {
  font-family: "Roboto Slab", serif;
  line-height: 1.3; }

h2,
.h2 {
  margin-bottom: 13px;
  font-family: "Roboto Slab", serif;
  line-height: 1.7;
  font-weight: normal; }
  @media (min-width: 1024px) {
    h2,
    .h2 {
      line-height: 1.4;
      margin-bottom: 35px; } }

.h3,
h3 {
  color: #77775F;
  line-height: 1.4;
  margin-bottom: 32px; }

.h4,
h4 {
  color: #4F2121;
  margin-bottom: 28px; }

p {
  margin: 0 0 32px; }
  p a {
    font-weight: 700;
    text-decoration: underline; }

a {
  color: #816E96;
  text-decoration: none;
  outline: none; }
  a:hover, a:focus {
    color: #000; }

a[href*="mailto:"] {
  word-wrap: break-word; }

[id="header"] {
  position: relative;
  background: #fff;
  padding: 32px 0 29px 0;
  z-index: 2; }
  @media (min-width: 1024px) {
    [id="header"] .btn {
      padding: 10px 22px; } }

.header-holder {
  display: flex;
  align-items: center; }

.logo {
  width: 162px;
  min-width: 100px; }
  @media (min-width: 1024px) {
    .logo {
      margin-left: -5px; } }

.top-menu {
  order: 1;
  font-size: 20px; }
  @media (min-width: 1024px) {
    .top-menu {
      margin-bottom: 12px;
      font-size: 18px; } }
  @media (min-width: 1024px) {
    .top-menu li:last-child span {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(255, 255, 255, 255) !important;
      white-space: nowrap !important;
      border: 0 !important;
      color: #fff; } }
  .top-menu li:last-child i {
    display: none; }
    @media (min-width: 1024px) {
      .top-menu li:last-child i {
        display: block; } }
  @media (min-width: 1024px) {
    .top-menu li + li {
      margin-left: 25px; } }

@media (min-width: 1024px) {
  .primary-menu-holder {
    display: flex;
    justify-content: flex-end;
    align-items: center; } }

.primary-menu-holder .btn {
  margin-bottom: 0; }
  @media (max-width: 1023px) {
    .primary-menu-holder .btn {
      background-color: transparent;
      border-radius: 0;
      padding: 13px 0;
      display: block; }
      .primary-menu-holder .btn:focus {
        background-color: transparent; }
      .primary-menu-holder .btn:hover {
        background-color: #816E96; } }
  @media (min-width: 1024px) {
    .primary-menu-holder .btn {
      margin-left: 24px; } }

@media (min-width: 1024px) {
  .primary-menu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 0; } }

@media (min-width: 1024px) {
  .primary-menu li:first-child {
    display: none; } }

@media (min-width: 1024px) {
  .primary-menu li + li {
    margin-left: 31px; } }

@media (min-width: 1440px) {
  .primary-menu li + li {
    margin-left: 31px; } }

[id="nav"] {
  flex-grow: 1; }

.nav-drop {
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  background: #000;
  position: absolute;
  color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 50px 0 96px 0;
  font-size: 20px;
  opacity: 0;
  visibility: hidden;
  text-align: center; }
  @media (min-width: 1024px) {
    .nav-drop {
      display: block;
      padding: 0;
      position: static;
      opacity: 1;
      visibility: visible;
      background-color: transparent;
      color: #000;
      text-align: left; } }
  .nav-active .nav-drop {
    opacity: 1;
    visibility: visible; }
  @media (min-width: 1024px) {
    .nav-drop > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; } }
  .nav-drop li {
    position: relative;
    list-style: none;
    transition: .3s background-color; }
    @media (max-width: 1023px) {
      .nav-drop li.active {
        background-color: #816E96; } }
    @media (min-width: 1024px) {
      .nav-drop li.active a {
        color: #816E96; } }
    @media (max-width: 1023px) {
      .nav-drop li:hover {
        background-color: #816E96; } }
    @media (min-width: 1024px) {
      .nav-drop li:hover > a {
        color: #816E96; } }
    .nav-drop li:hover > ul {
      display: block; }
    .nav-drop li > ul {
      background: #ddd;
      display: none;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: 2; }
      @media (min-width: 1024px) {
        .nav-drop li > ul {
          padding-top: 10px;
          width: 120px;
          position: absolute; } }
      @media (min-width: 1024px) {
        .nav-drop li > ul ul {
          padding-top: 0;
          position: absolute;
          left: 100%;
          top: 0;
          width: 120px; } }
      .nav-drop li > ul li {
        padding: 0 10px; }
  .nav-drop a {
    text-decoration: underline;
    display: block;
    padding: 13px 0; }
    @media (min-width: 1024px) {
      .nav-drop a {
        padding: 0; } }
    @media (min-width: 1024px) {
      .nav-drop a {
        text-decoration: none; } }
    .nav-drop a:not(.btn) {
      color: inherit;
      transition: .3s color; }

.nav-opener {
  position: relative;
  width: 58px;
  height: 35px;
  font-size: 0;
  line-height: 0;
  float: right;
  margin-right: -7px;
  outline: none; }
  @media (min-width: 1024px) {
    .nav-opener {
      display: none; } }
  .nav-opener:before, .nav-opener:after,
  .nav-opener span {
    transition: transform 0.5s ease-in-out;
    background: #617798;
    position: absolute;
    top: 13px;
    left: 15%;
    right: 15%;
    height: 9px;
    border-radius: 8px; }
  .nav-opener:before, .nav-opener:after {
    content: '';
    top: 0; }
  .nav-opener:after {
    top: auto;
    bottom: 0; }
  .nav-opener:hover {
    opacity: 0.9; }
  .nav-active .nav-opener span {
    opacity: 0;
    background: #000; }
  .nav-active .nav-opener:after, .nav-active .nav-opener:before {
    transform: rotate(45deg);
    top: 16px;
    left: 15%;
    right: 15%;
    background: #000; }
  .nav-active .nav-opener:after {
    transform: rotate(-45deg); }

.resize-active .nav-drop,
.resize-active .nav-drop > ul {
  transition: none !important; }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?lb8mgv");
  src: url("fonts/icomoon.eot?lb8mgv#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?lb8mgv") format("truetype"), url("fonts/icomoon.woff?lb8mgv") format("woff"), url("fonts/icomoon.svg?lb8mgv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-search:before {
  content: "\e900"; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.heading {
  margin-bottom: 35px;
  font-size: 20px; }
  @media (min-width: 1024px) {
    .heading {
      margin-bottom: 30px; } }
  .heading .text {
    display: inline-block;
    font-family: "Roboto Slab", serif;
    margin-bottom: 8px; }
    @media (min-width: 400px) {
      .heading .text {
        margin-bottom: 0; } }
  .heading a {
    display: block;
    font-size: 18px;
    text-decoration: underline;
    font-weight: 700; }
    @media (min-width: 400px) {
      .heading a {
        display: inline-block;
        margin-left: 17px; } }

.card {
  transition: background-color 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  background-color: #77775F;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
  max-width: 220px;
  width: 100%; }
  @media (max-width: 479px) {
    .card {
      margin-left: auto;
      margin-right: auto; } }
  .card > * {
    width: 100%; }
  .card header {
    height: 120px;
    max-height: 120px;
    overflow: hidden;
    display: flex; }
    .card header .card-img {
      transition: transform 0.5s ease-in-out;
      display: flex;
      width: 100%;
      background-position: center; }
  .card .content {
    padding: 30px 36px 29px 40px; }
  .card .card-title {
    font-size: 20px;
    text-decoration: underline;
    min-height: 50px;
    margin-bottom: 20px;
    color: #fff;
    font-family: "Roboto Slab", serif;
    line-height: 1.3; }
  .card time {
    font-size: 16px;
    color: #fff; }
  .card:hover {
    background-color: #929277; }
    .card:hover .card-img {
      transform: scale(1.1); }

.card-text {
  transition: opacity 0.5s ease-in-out;
  position: relative;
  font-size: 18px;
  line-height: 1.4;
  z-index: 1; }
  .card-text:after {
    content: " ";
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 50vh;
    z-index: -1;
    background-color: currentColor; }
  .card-text:hover {
    opacity: .8; }
  .card-text .inner {
    color: #fff;
    padding: 25px 42px; }
    @media (min-width: 768px) {
      .card-text .inner {
        padding: 35px 42px 44px 39px; } }
    @media (min-width: 1440px) {
      .card-text .inner {
        padding: 44px 30px 50px 40px; } }
  .card-text .title {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    margin-bottom: 3px;
    text-decoration: underline;
    font-family: "Roboto Slab", serif; }
  .card-text p {
    margin-bottom: 0; }

.card-resources {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  transition: .3s background-color;
  cursor: pointer;
  min-height: 180px; }
  @media (min-width: 768px) {
    .card-resources {
      min-height: 120px; } }
  .card-resources:hover {
    background-color: #000 !important; }
  .card-resources .content {
    padding: 19px 40px 19px 40px; }
    @media (min-width: 768px) {
      .card-resources .content {
        padding: 9px 40px 18px 40px; } }
  .card-resources .card-title {
    text-decoration: underline;
    color: #fff;
    font-family: "Roboto Slab", serif;
    margin-bottom: 0; }
  .card-resources a {
    color: inherit; }

.card-news {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 33px; }
  .card-news time {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 6px;
    color: #617798; }
  .card-news .card-title {
    margin-bottom: 12px;
    color: #544861;
    text-decoration: underline;
    transition: .3s color;
    font-size: 18px; }
    @media (min-width: 1024px) {
      .card-news .card-title {
        margin-bottom: 1px; } }
    @media (min-width: 1440px) {
      .card-news .card-title {
        margin-bottom: 12px; } }
    .card-news .card-title:hover {
      color: #816E96; }
    .card-news .card-title a {
      color: inherit; }
  .card-news p {
    margin-bottom: 0; }

.btn {
  background: #000;
  color: #fff;
  border: #000;
  border-radius: 8px;
  display: inline-block;
  font-size: 20px;
  line-height: 1.15;
  letter-spacing: .03em;
  padding: 4px 10px;
  margin: 0 0 20px;
  vertical-align: top;
  text-align: center;
  transition: background .3s, color .3s;
  font-family: "Open Sans", sans-serif, "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  outline: none; }
  .btn:focus {
    background: #000;
    color: #fff;
    border: #000; }
  .btn:hover {
    background: black;
    color: #fff;
    border: black; }
  @media (min-width: 768px) {
    .btn {
      padding: 10px 25px; } }
  .btn-default {
    background: #000;
    color: #fff;
    border: #000; }
    .btn-default:focus {
      background: #000;
      color: #fff;
      border: #000; }
    .btn-default:hover {
      background: black;
      color: #fff;
      border: black; }
  .btn-primary,
  .btn input[type="submit"] {
    background: #617798;
    color: #fff;
    border: #617798; }
    .btn-primary:focus,
    .btn input[type="submit"]:focus {
      background: #617798;
      color: #fff;
      border: #617798; }
    .btn-primary:hover,
    .btn input[type="submit"]:hover {
      background: black;
      color: #fff;
      border: black; }
  .btn-warning {
    background: #ffc107;
    color: #fff;
    border: #ffc107; }
    .btn-warning:focus {
      background: #ffc107;
      color: #fff;
      border: #ffc107; }
    .btn-warning:hover {
      background: #d39e00;
      color: #fff;
      border: #d39e00; }
  .btn-sm {
    border-radius: 0;
    padding: 4px 10px; }

.btn-group {
  display: flex; }
  .btn-group > .btn + .btn {
    border-left: 0; }

.btn-wrap {
  display: flex;
  flex-wrap: wrap; }
  .btn-wrap .btn {
    margin: 0 0 10px;
    max-width: 50%; }
    .btn-wrap .btn + .btn {
      margin-left: 20px; }

.breadcrumbs-nav {
  padding: 60px 0 67px 0; }
  @media (min-width: 1024px) {
    .breadcrumbs-nav {
      padding: 50px 0 45px 0; } }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  color: #666;
  font-size: 16px; }
  .breadcrumbs li {
    margin-right: 10px;
    margin-top: 10px; }
    .breadcrumbs li + li:before {
      content: ">";
      margin-right: 10px; }
  .breadcrumbs a {
    text-decoration: underline;
    color: inherit;
    transition: .3s color; }
    .breadcrumbs a:hover {
      color: #816E96; }

.section-banner {
  display: flex;
  background-size: cover;
  background-position: center;
  padding: 20px 0; }
  .section-banner > * {
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; }
  .section-banner .banner-text {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    text-align: center; }
    @media (min-width: 768px) {
      .section-banner .banner-text {
        max-width: 350px; } }
    .section-banner .banner-text h1 {
      margin-bottom: 0; }
  .section-banner .title {
    margin-bottom: 10px; }
  .section-banner-home .banner-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 355px;
    z-index: 1; }
    @media (min-width: 1024px) {
      .section-banner-home .banner-img {
        position: absolute;
        width: 59.1vw;
        top: 0;
        bottom: 0;
        right: 0;
        height: auto; } }
  .section-banner-home .banner-thumbanail {
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    z-index: 1; }
    @media (min-width: 1024px) {
      .section-banner-home .banner-thumbanail {
        display: flex;
        align-items: flex-end; } }
    .section-banner-home .banner-thumbanail .text {
      position: relative;
      font-size: 20px;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 2;
      line-height: 1.6;
      padding: 25px 42px; }
      @media (min-width: 768px) {
        .section-banner-home .banner-thumbanail .text {
          padding: 32px 42px 43px 42px;
          font-size: 22px; } }
      @media (min-width: 1440px) {
        .section-banner-home .banner-thumbanail .text {
          padding: 42px 15px 65px 19px; } }
      .section-banner-home .banner-thumbanail .text:after {
        content: " ";
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 50vh;
        z-index: -1;
        background-color: currentColor; }
      .section-banner-home .banner-thumbanail .text p {
        color: #445C6A;
        margin-bottom: 0; }
        .section-banner-home .banner-thumbanail .text p a {
          text-decoration: underline;
          font-weight: 400; }
  .section-banner-home .col {
    display: flex; }
    @media (min-width: 1024px) {
      .section-banner-home .col:first-child {
        max-width: 58.5%;
        flex: 0 0 58.5%; } }
    @media (min-width: 1440px) {
      .section-banner-home .col:first-child {
        max-width: 56%;
        flex: 0 0 56%; } }
    .section-banner-home .col:nth-child(2) {
      flex-direction: column; }
      @media (min-width: 1024px) {
        .section-banner-home .col:nth-child(2) {
          flex-direction: column;
          max-width: 41.5%;
          flex: 0 0 41.5%; } }
      @media (min-width: 1440px) {
        .section-banner-home .col:nth-child(2) {
          max-width: 44%;
          flex: 0 0 44%; } }
    .section-banner-home .col > * {
      width: 100%; }

.section-cards {
  padding: 20px 0; }
  @media (min-width: 768px) {
    .section-cards {
      padding: 40px 0; } }
  .section-cards .card-img {
    text-align: center;
    margin-bottom: 10px; }
  .section-cards .card-title {
    margin-bottom: 10px; }
  .section-cards .btn-wrap {
    justify-content: center; }

.section-upcoming-events {
  padding-top: 58px;
  padding-bottom: 55px;
  background-image: linear-gradient(#B4C2CE, #fff); }
  .section-upcoming-events hr {
    margin-bottom: 0;
    margin-top: 60px; }
  .section-upcoming-events .card-block .df-row {
    display: block;
    margin: 0 -10px; }
  .section-upcoming-events .card-block .slick-track {
    display: flex; }
    .section-upcoming-events .card-block .slick-track:after, .section-upcoming-events .card-block .slick-track:before {
      display: none; }
  .section-upcoming-events .card-block .slick-dots {
    display: flex;
    justify-content: center;
    padding: 25px 0 0 0;
    font-size: 0; }
    .section-upcoming-events .card-block .slick-dots li + li {
      margin-left: 15px; }
    .section-upcoming-events .card-block .slick-dots li.slick-active button {
      background-color: #816E96; }
    .section-upcoming-events .card-block .slick-dots li button {
      width: 15px;
      height: 15px;
      background-color: #544861;
      border-radius: 50%;
      padding: 0;
      font-size: 0; }
  .section-upcoming-events .card-block .col {
    display: flex;
    height: auto;
    padding: 0 10px; }

.section-popular-resources .df-row {
  margin: 0 -30px; }

.section-popular-resources .col {
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0 30px; }
  .section-popular-resources .col + .col {
    margin-top: 36px; }
    @media (min-width: 1024px) {
      .section-popular-resources .col + .col {
        margin-top: 0; } }
  @media (min-width: 1024px) {
    .section-popular-resources .col:first-child {
      max-width: 52%;
      flex: 0 0 52%; } }
  @media (min-width: 1440px) {
    .section-popular-resources .col:first-child {
      max-width: 53.5%;
      flex: 0 0 53.5%; } }
  @media (min-width: 1024px) {
    .section-popular-resources .col:nth-child(2) {
      max-width: 48%;
      flex: 0 0 48%; } }
  @media (min-width: 1440px) {
    .section-popular-resources .col:nth-child(2) {
      max-width: 46.5%;
      flex: 0 0 46.5%; } }

.card-resources-holder .df-row {
  margin: 0 -10px; }

.card-resources-holder .col-cards {
  display: flex;
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0 10px 20px 10px; }
  @media (min-width: 576px) {
    .card-resources-holder .col-cards {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (min-width: 768px) {
    .card-resources-holder .col-cards {
      max-width: 33.3333333%;
      flex: 0 0 33.3333333%; } }
  @media (min-width: 1024px) {
    .card-resources-holder .col-cards {
      max-width: 50%;
      flex: 0 0 50%; } }
  @media (min-width: 1440px) {
    .card-resources-holder .col-cards {
      max-width: 33.3333333%;
      flex: 0 0 33.3333333%; } }
  .card-resources-holder .col-cards > * {
    width: 100%; }

.section-sponsors {
  padding-top: 58px;
  padding-bottom: 160px; }
  @media (min-width: 576px) {
    .section-sponsors {
      padding-top: 48px;
      padding-bottom: 60px; } }
  @media (min-width: 768px) {
    .section-sponsors {
      padding-top: 25px;
      padding-bottom: 60px; } }
  @media (min-width: 1440px) {
    .section-sponsors {
      padding-top: 78px; } }
  .section-sponsors .container {
    position: relative;
    z-index: 1; }
  .section-sponsors hr {
    margin-top: 0;
    margin-bottom: 47px; }
  .section-sponsors .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      .section-sponsors .header-section {
        margin-bottom: 12px; } }
    .section-sponsors .header-section .heading,
    .section-sponsors .header-section .btn {
      margin-bottom: 0; }
    .section-sponsors .header-section .btn {
      position: absolute;
      left: 40px;
      min-width: 240px;
      bottom: -104px;
      min-width: 240px;
      padding-top: 11px;
      padding-bottom: 11px; }
      @media (min-width: 576px) {
        .section-sponsors .header-section .btn {
          position: static; } }
  @media (min-width: 576px) {
    .section-sponsors .partners-list {
      display: flex;
      align-items: center; } }
  .section-sponsors .partners-list li + li {
    margin-top: 31px; }
    @media (min-width: 576px) {
      .section-sponsors .partners-list li + li {
        margin-left: 50px;
        margin-top: 0; } }

section.section-post-info {
  padding-bottom: 15px; }
  section.section-post-info h2 {
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 45px; }
    @media (min-width: 1024px) {
      section.section-post-info h2 {
        font-size: 30px;
        margin-bottom: 45px; } }
  @media (min-width: 1024px) {
    section.section-post-info .card-resources-holder .col-cards {
      max-width: 33.3333333%;
      flex: 0 0 33.3333333%; } }
  section.section-post-info .card-resources {
    min-height: 180px; }
    @media (min-width: 1024px) {
      section.section-post-info .card-resources {
        min-height: 260px; } }
    @media print {
      section.section-post-info .card-resources {
        min-height: auto; } }
    section.section-post-info .card-resources .card-title {
      line-height: 1.45; }
      @media (min-width: 1024px) {
        section.section-post-info .card-resources .card-title {
          font-size: 30px; } }

.section-search-result {
  padding-bottom: 38px; }
  .section-search-result h3 {
    margin-bottom: 30px; }
    @media (min-width: 1024px) {
      .section-search-result h3 {
        margin-bottom: 52px; } }

.section-content {
  padding-bottom: 7px; }
  .section-content ul {
    margin-bottom: 40px; }
    .section-content ul li {
      padding-left: 12px; }
      .section-content ul li + li {
        margin-top: 3px; }
      .section-content ul li:before {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #000; }
  .section-content .btn {
    padding: 10px 25px; }
  .section-content .table-holder {
    overflow-x: auto; }

body {
  width: 100%; }

[id="wrapper"] {
  width: 100%;
  overflow: hidden;
  background: #fff; }

.container {
  max-width: 1390px;
  margin: 0 auto;
  padding: 0 38px; }
  @media (min-width: 768px) {
    .container {
      padding: 0 36px; } }
  @media (min-width: 1024px) {
    .container {
      padding: 0 42px; } }
  @media (min-width: 1440px) {
    .container {
      padding: 0 15px; } }

a {
  transition: .3s color; }

hr,
.hr {
  display: block;
  height: 5px;
  background-color: #B4C2CE;
  border: 0;
  margin: 41px 0 53px 0; }

table {
  width: 100%;
  border: 2px solid #ddd;
  margin-top: 45px;
  margin-bottom: 30px; }
  table th {
    background-color: #EDEDF3;
    font-weight: normal;
    text-align: left;
    border: 2px solid #ddd; }
  table th,
  table td {
    width: 25%;
    padding: 22px 29px; }
  table p {
    margin-bottom: 0; }

figure {
  margin-left: 0;
  margin-right: 0; }
  figure img {
    border: 2px solid #707070;
    border-radius: 8px;
    vertical-align: top;
    line-height: 1; }
  figure figcaption {
    margin-top: 10px; }

.accessibility {
  display: block;
  padding: 15px;
  background-color: #816E96;
  color: #fff; }
  .accessibility:not(:focus) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(255, 255, 255, 255) !important;
    white-space: nowrap !important;
    border: 0 !important;
    color: #fff; }

.hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(255, 255, 255, 255) !important;
  white-space: nowrap !important;
  border: 0 !important;
  color: #fff; }

noscript div {
  text-align: center;
  padding: 15px 5px;
  color: crimson;
  background-color: #afaeae; }

#footer {
  background: #fff; }

.footer-nav {
  padding-top: 60px;
  padding-bottom: 54px;
  background-color: #B4C2CE; }
  @media (min-width: 1440px) {
    .footer-nav {
      padding-bottom: 30px; } }
  .footer-nav .df-row {
    margin: 0 -20px; }
  .footer-nav .menu-item {
    max-width: 100%;
    flex: 0 0 100%;
    color: #2C2D44;
    font-size: 16px;
    padding: 0 20px 0 20px; }
    @media (min-width: 576px) {
      .footer-nav .menu-item {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 0 20px 36px 20px; } }
    @media (min-width: 768px) {
      .footer-nav .menu-item {
        padding: 0 20px 36px 20px;
        max-width: 33.3333333%;
        flex: 0 0 33.3333333%; } }
    @media (min-width: 1024px) {
      .footer-nav .menu-item {
        max-width: 25%;
        flex: 0 0 25%;
        padding: 0 20px 20px 20px; } }
    @media (min-width: 1440px) {
      .footer-nav .menu-item {
        max-width: 16.6666667%;
        flex: 0 0 16.6666667%; } }
    .footer-nav .menu-item .title {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 9px;
      font-size: 18px;
      line-height: 1.4;
      font-family: "Roboto Slab", serif;
      font-weight: 500;
      color: #2C2D44; }
      @media (min-width: 576px) {
        .footer-nav .menu-item .title {
          margin-bottom: 16px; } }
    .footer-nav .menu-item a {
      color: inherit;
      text-decoration: underline; }
      .footer-nav .menu-item a:hover {
        text-decoration: none; }

.footer-menu {
  line-height: 1.5; }
  .footer-menu li + li {
    margin-top: 10px; }
  .footer-menu li.active a {
    text-decoration: none; }

.footer-contacts {
  font-size: 18px; }
  @media (min-width: 1024px) {
    .footer-contacts .inner {
      display: flex; } }
  .footer-contacts .inner .col {
    display: flex; }
    .footer-contacts .inner .col:first-child {
      flex: 1;
      min-width: 1px; }
    .footer-contacts .inner .col:nth-child(2) {
      max-width: 460px;
      flex: 0 0 460px; }
    .footer-contacts .inner .col > * {
      width: 100%; }
  .footer-contacts h4 {
    font-weight: 400;
    margin-bottom: 15px;
    font-family: "Roboto Slab", serif; }
  .footer-contacts a {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    color: #544861; }
    .footer-contacts a:hover {
      text-decoration: none; }
  .footer-contacts .subscribe-block {
    max-width: 480px;
    padding: 60px 0 68px 0; }
    @media (min-width: 1024px) {
      .footer-contacts .subscribe-block {
        padding: 60px 42px 68px 0;
        max-width: 100%; } }
    @media (min-width: 1440px) {
      .footer-contacts .subscribe-block {
        padding: 60px 60px 68px 0; } }
    .footer-contacts .subscribe-block h4 {
      font-weight: 700;
      margin-bottom: 20px;
      color: #000; }
      @media (min-width: 768px) {
        .footer-contacts .subscribe-block h4 {
          font-weight: 400;
          margin-bottom: 15px; } }
    .footer-contacts .subscribe-block p {
      margin-bottom: 28px;
      line-height: 1.4; }
    .footer-contacts .subscribe-block form {
      margin-bottom: 26px;
      max-width: 440px; }
      @media (min-width: 576px) {
        .footer-contacts .subscribe-block form {
          display: flex; } }
      .footer-contacts .subscribe-block form label {
        display: inline-block;
        vertical-align: middle;
        max-width: 240px;
        width: 100%;
        margin-bottom: 28px; }
        @media (min-width: 576px) {
          .footer-contacts .subscribe-block form label {
            flex: 1;
            max-width: none;
            min-width: 1px;
            margin-bottom: 0; } }
        @media (min-width: 576px) {
          .footer-contacts .subscribe-block form label + .btn {
            margin-left: 14px; } }
      .footer-contacts .subscribe-block form input {
        width: 100%; }
      .footer-contacts .subscribe-block form .btn {
        max-width: 124px;
        flex: 0 0 124px;
        width: 100%;
        margin-bottom: 0;
        padding-left: 10px;
        padding-right: 10px; }
  .footer-contacts .contact-block {
    position: relative;
    padding: 60px 0 68px 0;
    background-color: #DCB9AE;
    color: #4F2121;
    z-index: 1; }
    @media (min-width: 1024px) {
      .footer-contacts .contact-block {
        padding: 60px 0 68px 60px; } }
    .footer-contacts .contact-block:after {
      content: " ";
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 100vh;
      z-index: -1;
      background-color: #DCB9AE; }
    @media (max-width: 1023px) {
      .footer-contacts .contact-block:before {
        content: " ";
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 100vh;
        z-index: -1;
        background-color: #DCB9AE; } }
    .footer-contacts .contact-block address {
      font-size: 18px;
      margin-bottom: 15px;
      font-style: normal; }
      .footer-contacts .contact-block address p {
        margin-bottom: 0; }
        .footer-contacts .contact-block address p + p {
          margin-top: 7px; }
          @media (min-width: 1024px) {
            .footer-contacts .contact-block address p + p {
              margin-top: 7px; } }
    .footer-contacts .contact-block a {
      color: inherit;
      font-size: 18px; }

.footer-bottom {
  padding: 38px 0 39px;
  background-color: #000;
  color: #fff; }
  .footer-bottom a {
    color: inherit;
    text-decoration: underline; }
    .footer-bottom a:hover {
      text-decoration: none; }
  @media (min-width: 768px) {
    .footer-bottom .inner {
      display: flex;
      justify-content: space-between; } }

.footer-bottom-menu {
  font-size: 16px;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .footer-bottom-menu {
      display: flex;
      margin-bottom: 0; } }
  .footer-bottom-menu li + li {
    margin-top: 15px; }
    @media (min-width: 768px) {
      .footer-bottom-menu li + li {
        margin-left: 40px;
        margin-top: 0; } }
  .footer-bottom-menu li.active a {
    text-decoration: none; }

.copyright {
  font-size: 16px;
  line-height: 1.4; }
  @media (max-width: 767px) {
    .copyright {
      max-width: 200px;
      text-align: right;
      margin-left: auto; } }
  .copyright p {
    margin-bottom: 0; }
