.heading {
    margin-bottom: 35px;
    font-size: 20px;
    
    
    @include media('≥1024px') {
        margin-bottom: 30px;
    }

    .text {
        display: inline-block;
        font-family: $font-family-roboto-slab;
        margin-bottom: 8px;

        @include media('≥400px') {
            margin-bottom: 0;
        }
    }

    a {
        display: block;
        font-size: 18px;
        text-decoration: underline;
        font-weight: 700;

        @include media('≥400px') {
            display: inline-block;
            margin-left: 17px;
        }
    }

}