.section-banner {
	@include v-center(160px);

	background-size: cover;
	background-position: center;
	padding: 20px 0;

	.banner-text {
		max-width: 260px;
		margin-left: auto;
		margin-right: auto;
		color: $white;
		text-align: center;

		@include media('≥tablet') {
			max-width: 350px;
		}

		h1 {
			margin-bottom: 0;
		}
	}

	.title {
		margin-bottom: 10px;
	}

	&-home {
		.banner-img {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 355px;
			z-index: 1;
			
			@include media('≥1024px') {
				position: absolute;
				width: 59.1vw;
				top: 0;
				bottom: 0;
				right: 0;
				height: auto;
			}
		}

		.banner-thumbanail {
			position: relative;
			color: rgba($color: $white, $alpha: .8);
			z-index: 1;
			
			@include media('≥1024px') {
				display: flex;
				align-items: flex-end;
			}

			.text {
				position: relative;
				font-size: 20px;
				background-color: rgba($color: $white, $alpha: .8);
				z-index: 2;
				line-height: 1.6;
				padding: 25px 42px;
				
				@include media('≥768px') {
					padding: 32px 42px 43px 42px;
					font-size: 22px;
				}

				@include media('≥1440px') {
					padding: 42px 15px 65px 19px;
				}
				
				&:after {
					content: " ";
					position: absolute;
					right: 100%;
					top: 0;
					bottom: 0;
					width: 50vh;
					z-index: -1;
					background-color: currentColor;
				}

				p {
					color: #445C6A;
					margin-bottom: 0;

					a {
						text-decoration: underline;
						font-weight: 400;
					}
				}
			}
		}

		.col {
			display: flex;

			&:first-child {

				@include media('≥1024px') {
					max-width: 58.5%;
					flex: 0 0 58.5%;
				}

				@include media('≥1440px') {
					max-width: 56%;
					flex: 0 0 56%;
				}
			}

			&:nth-child(2) {
				flex-direction: column;

				@include media('≥1024px') {
					flex-direction: column;
					max-width: 41.5%;
					flex: 0 0 41.5%;
				}

				@include media('≥1440px') {
					max-width: 44%;
					flex: 0 0 44%;
				}
			}

			> * {
				width: 100%;
			}
		}
	}
}