.section-upcoming-events {
    padding-top: 58px;
    padding-bottom: 55px;
    background-image: linear-gradient(#B4C2CE, $white);

    hr {
        margin-bottom: 0;
        margin-top: 60px;
    }

    .card-block {
        .df-row {
            display: block;
            margin: 0 -10px;
        }

        .slick-track {
            display: flex;

            &:after,
            &:before {
                display: none;
            }
        }

        .slick-dots {
            @extend %listreset;

            display: flex;
            justify-content: center;
            padding: 25px 0 0 0;
            font-size: 0;

            li {
                + li {
                    margin-left: 15px;
                }



                &.slick-active {
                    button {
                        background-color: $purple;
                    }
                }

                button {
                    width: 15px;
                    height: 15px;
                    background-color: #544861;
                    border-radius: 50%;
                    padding: 0;
                    font-size: 0;

                }
            }
        }

        .col {
            display: flex;
            height: auto;
            padding: 0 10px;
        }
    }
}